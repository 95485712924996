<template>
  <div class="addHospitalAdmin pb-4">
    <h3 class="pb-3 px-1 mb-0"></h3>
    <ValidationObserver ref="addHospital" v-slot="{ handleSubmit }">
      <form id="addHospital" @submit.prevent="handleSubmit(onSubmit)">
        <CCardGroup class="has-loading-overlay">
          <CCard class="p-2">
            <CCardBody>
              <CRow class="row mb-3" v-if="isAdmin">
                <label class="required col-lg-3 col-md-12">Hospital Name</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <Select
                      name="hospital_name"
                      :value="hospitals.hospital_name"
                      :options="options && options['hospital_name'] ? options['hospital_name'] : []"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                      :error="errors[0]"
                      @input="handleChangeSelect"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">First Name</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider :rules="{ required: true, name: 25 }" v-slot="{errors}">
                    <TextInput
                      name="first_name"
                      :value="hospitals.first_name"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Last Name</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider :rules="{ required: true, name: 25 }" v-slot="{errors}">
                    <TextInput
                      name="surname"
                      :value="hospitals.surname"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Phone</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required" v-slot="{errors}">
                    <PhoneInput
                      name="contact_phone"
                      :value="hospitals.contact_phone"
                      :options="options && options['dialCode'] ? options['dialCode'] : []"
                      label="dialing_code"
                      @change="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Email</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required|email" v-slot="{errors}">
                    <TextInput
                      name="email"
                      :value="hospitals.email"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Password</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider
                    name="password"
                    :rules="{ required: true, password_length: 8 }"
                    v-slot="{errors}"
                  >
                    <TextInput
                      name="password"
                      type= "password"
                      :value="hospitals.password"
                      @input="handleInput"
                      :error="errors[0]"
                    />
                    <small class="hint">Password format: 8 characters</small>
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow class="row mb-3">
                <label class="required col-lg-3 col-md-12">Confirm Password</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <ValidationProvider rules="required|confirmed:password" v-slot="{errors}">
                    <TextInput
                      name="confirmPassword"
                      :value="confirmPassword"
                      @input="handleConfirmPassword"
                      type="password"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
              </CRow>
              <CRow v-if="false" class="row mb-3">
                <label class="col-lg-3 col-md-12">Date of Birth</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                   <DateInput
                      name="dob"
                      :value="hospitals.dob"
                      @input="handleDatePickerChange"
                    />
                </div>
              </CRow>
              <CRow v-if="false" class="row mb-3">
                <label class="col-lg-3 col-md-12">Website</label>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <TextInput name="website" :value="hospitals.website" @input="handleInput" />
                </div>
              </CRow>
            </CCardBody>
          </CCard>
        </CCardGroup>
        <div class="text-center mx-0 mt-4 pt-4 pb-4" style="padding-bottom:150px!important">
          <button class="btn rounded-0 px-5 btn-primary btn-lg" type="submit">Submit</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from "vue";
import m from "moment";
import { times } from "lodash";
import { extend } from "vee-validate";
import { required, email, confirmed, min, max } from "vee-validate/dist/rules";
import { mapGetters, mapActions } from "vuex";
import { getScope, Role } from "@/helpers/helper";

extend("required", { ...required, message: "This field is required" });
extend("name", { ...max, message: "Max length: 25 character"})
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters"
});
extend("confirmed", { ...confirmed, message: "This field should match password" });
extend("email", { ...email, message: "Invalid email" });

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import DateInput from "@/components/reusable/Fields/DateInput";
export default {
  components: {
    Select,
    TextInput,
    DateInput,
    PhoneInput
  },
  data() {
    return {
      isFileError: false,
      confirmPassword: null,
      hospitals: {},
      payload: {},
      currentUserRole: getScope()
    };
  },
  computed: {
    ...mapGetters(["locationCountryInfo", "hospitalNames", "getRoleScopes","dialingCodeWithCountryCode"]),
    isAdmin(){
      if (this.currentUserRole === Role.systemAdmin) {
        return true;
      }
      return false;
    },
    options() {
      return {
        dialCode: this.dialingCodeWithCountryCode || [],
        hospital_name: this.hospitalNames || []
      };
    }
  },
  mounted() {
    this.initAddHospitalAdminFetchOptions(this.isAdmin);
  },
  methods: {
    ...mapActions(["initAddHospitalAdminFetchOptions", "showToast"]),
    handleInput(name, value) {
      Vue.set(this.hospitals, name, value);
      Vue.set(this.payload, name, value);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.hospitals, name, value);
      Vue.set(this.payload, name, m(value).format("YYYY-MM-DD"));
    },
    handleChangeSelect(name, value) {
      Vue.set(this.hospitals, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null
      };
    },
    handleConfirmPassword(name, value) {
      this[name] = value
    },
    async onSubmit() {
      const isValid = await this.$refs.addHospital.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!"
        });
        return;
      }
      this.$emit("submit", this.payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.hint {
  opacity: 0.7;
}
</style>

